import './Home.css';

function Home() {

    return(
        <div className='home'>
            <h1 className='title'>Bee Positive Apiary</h1>
        </div>
    );
}

export default Home;